import React, { useCallback } from 'react';
import { OrderMarker } from '@/Enums';
import { ModalProps, Nullable } from '@/Types';
import styles from './ChangeOtherStatusesModal.scss';
import { useLocalization } from '@/Hooks';
import sharedStyles from '../ModalWindows.scss';
import { Button } from '@/Components/Controls/Button/Button';
import { BUTTON_THEMES } from '@/Components/Controls/Button/ButtonThemes';
import { FormSelectInput } from '@/Components/Controls/SelectInput/FormSelectInput';
import { Modal } from '@/Components/Modal/Modal';
import { useChangeOtherStatusesForm } from './hooks/useChangeOtherStatusesForm';
import { useChangeOtherStatusesOptions } from './hooks/useChangeOtherStatusesOptions';
import { tourActions } from '@/Redux/Tours/reducer';
import { AppDispatch } from '@/Redux/ConfigureStore';
import { useDispatch } from 'react-redux';

type Props = ModalProps;

type FormData = {
  orderMarker: Nullable<OrderMarker>;
};

export function ChangeOtherStatusesModal({ closeModalWindow }: Props) {
  const dispatch: AppDispatch = useDispatch();

  const {
    buttons,
    modalWindows: { changeOtherStatuses },
    enums: { OrderMarker: OrderMarkerTranslation },
  } = useLocalization();

  const { isSalesPage, options, selectedOrders } = useChangeOtherStatusesOptions();

  const { errors, register, setValue, submit, rules } = useChangeOtherStatusesForm(
    selectedOrders,
    closeModalWindow,
    isSalesPage,
  );

  const onActivateTour = useCallback(() => {
    dispatch(tourActions.setRun({ run: true, tour: 'changeStatusTour' }));
  }, []);

  if (!selectedOrders?.length) {
    closeModalWindow();
    return null;
  }

  return (
    <Modal onClose={closeModalWindow} title={changeOtherStatuses.Title} onDocsClick={onActivateTour}>
      <form className={sharedStyles.modalWrapper} onSubmit={submit} id={'changeStatus'}>
        <FormSelectInput<FormData>
          name={'orderMarker'}
          options={options}
          className={styles.statusSelect}
          placeholder={OrderMarkerTranslation.Placeholder}
          error={errors}
          setValue={setValue}
          register={register}
          rules={{ ...rules.required }}
        />
        <div className={sharedStyles.buttonsWrapper}>
          <Button
            text={buttons.Cancel}
            theme={BUTTON_THEMES.SUCCESS_TRANSPARENT}
            onClick={closeModalWindow}
            type={'button'}
          />
          <Button autoFocus={true} text={buttons.Change} theme={BUTTON_THEMES.SUCCESS} type={'submit'} />
        </div>
      </form>
    </Modal>
  );
}
