import { useDispatch } from 'react-redux';
import { useLocalization } from '@/Hooks';
import React, { useMemo } from 'react';
import { TourType } from '@/Components/Tour/Tour';
import { modalWindowsActions } from '@/ModalWindows/services';
import { ModalType } from '@/Enums';

export function useModals() {
  const dispatch = useDispatch();

  const {
    tours: {
      Modals: {
        DocumentPrintTour,
        DocumentCreatingTour,
        ChangePaymentStatusTour,
        ChangeDeliveryStatusTour,
        ChangeStatusTour,
      },
    },
  } = useLocalization();

  const documentCreatingTour = useMemo<TourType>(
    () => ({
      id: 'documentCreatingTour',
      title: DocumentCreatingTour.Title,
      before: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.GenerateDocumentNumberModal }));
      },
      after: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.None }));
      },
      steps: [
        {
          content: <div>{DocumentCreatingTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#generateNumberTitle',
          title: DocumentCreatingTour.Step1.Title,
          disableBeacon: true,
          link: DocumentCreatingTour.Step1.DetailsLink,
        },
        {
          content: <div>{DocumentCreatingTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#documentFunctions',
          title: DocumentCreatingTour.Step2.Title,
          disableBeacon: true,
          link: DocumentCreatingTour.Step2.DetailsLink,
        },
      ],
    }),
    [DocumentCreatingTour],
  );

  const changePaymentStatusTour = useMemo<TourType>(
    () => ({
      id: 'changePaymentStatusTour',
      title: ChangePaymentStatusTour.Title,
      before: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.ChangePaymentStatus }));
      },
      after: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.None }));
      },
      steps: [
        {
          content: <div>{ChangePaymentStatusTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#changeStatus',
          title: ChangePaymentStatusTour.Step1.Title,
          disableBeacon: true,
          link: ChangePaymentStatusTour.Step1.DetailsLink,
        },
      ],
    }),
    [ChangePaymentStatusTour],
  );

  const changeDeliveryStatusTour = useMemo<TourType>(
    () => ({
      id: 'changeDeliveryStatusTour',
      title: ChangeDeliveryStatusTour.Title,
      before: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.ChangeDeliveryStatus }));
      },
      after: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.None }));
      },
      steps: [
        {
          content: <div>{ChangeDeliveryStatusTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#changeStatus',
          title: ChangeDeliveryStatusTour.Step1.Title,
          disableBeacon: true,
          link: ChangeDeliveryStatusTour.Step1.DetailsLink,
        },
      ],
    }),
    [ChangeDeliveryStatusTour],
  );

  const documentPrintTour = useMemo<TourType>(
    () => ({
      id: 'documentPrintTour',
      title: DocumentPrintTour.Title,
      before: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.DocumentTemplates }));
      },
      after: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.None }));
      },
      steps: [
        {
          content: <div>{DocumentPrintTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#documentPrintForm',
          title: DocumentPrintTour.Step1.Title,
          disableBeacon: true,
          link: DocumentPrintTour.Step1.DetailsLink,
        },
        {
          content: <div>{DocumentPrintTour.Step2.Text}</div>,
          placement: 'auto',
          target: '#sortPrinting',
          title: DocumentPrintTour.Step2.Title,
          disableBeacon: true,
          link: DocumentPrintTour.Step2.DetailsLink,
        },
      ],
    }),
    [DocumentPrintTour],
  );

  const changeStatusTour = useMemo<TourType>(
    () => ({
      id: 'changeStatusTour',
      title: ChangeStatusTour.Title,
      before: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.ChangeOtherStatuses }));
      },
      after: () => {
        dispatch(modalWindowsActions.openModal({ modalType: ModalType.None }));
      },
      steps: [
        {
          content: <div>{ChangeStatusTour.Step1.Text}</div>,
          placement: 'auto',
          target: '#changeStatus',
          title: ChangeStatusTour.Step1.Title,
          disableBeacon: true,
          link: ChangeStatusTour.Step1.DetailsLink,
        },
      ],
    }),
    [ChangeStatusTour],
  );

  return [documentCreatingTour, changePaymentStatusTour, changeDeliveryStatusTour, documentPrintTour, changeStatusTour];
}
