import Purchases from './Icons/purchases.png';
import ConnectionLost from './Icons/connection-lost.svg';

import Add from './Icons/add.png';
import AddBlue from './Icons/add-blue.svg';
import Delete from './Icons/delete.svg';
import Save from './Icons/save.png';
import Enter from './Icons/enter.svg';

import Back from './Icons/back.png';

import Copy from './Icons/copy.svg';
import Edit from './Icons/edit.svg';
import EditLight from './Icons/edit-light.svg';

import Merge from './Icons/merge.png';
import Divide from './Icons/divide.png';

import Home from './Icons/home.svg';
import Customers from './Icons/customers.png';
import Cart from './Icons/cart.png';

import Check from './Icons/check.png';
import Invalid from './Icons/invalid.svg';

import EmailBlue from './Icons/email-blue.svg';
import AddressWarning from './Icons/address-warning.png';
import { ClassNameProps, DataProps } from '@/Types';

import Logo from './Icons/logo.png';

import DashboardModule from './Icons/Modules/dashboard.svg';
import SalesModule from './Icons/Modules/sales.svg';
import Synchronize from './Icons/Modules/synchronize.svg';
import LogisticModule from './Icons/Modules/logistic.svg';
import PurchasesModule from './Icons/Modules/purchases.svg';
import MessagesModule from './Icons/Modules/mail.svg';
import DocumentModule from './Icons/Modules/document.svg';
import WarehouseModule from './Icons/Modules/warehouse.svg';
import CustomersModule from './Icons/Modules/customers.svg';
import SuppliersModule from './Icons/Modules/suppliers.svg';
import DesignServiceModule from './Icons/Modules/design.svg';
import OnlineArchiveModule from './Icons/Modules/archive.svg';
import ShopModule from './Icons/Modules/shop.svg';
import AdminModule from './Icons/Modules/admin.png';
import LanguagesModule from './Icons/Modules/languages.svg';
import AccountingModule from './Icons/Modules/accounting.svg';
import ListingModule from './Icons/Modules/listing.svg';
import SubscriptionsModule from './Icons/Modules/subscriptions.svg';
import DigitalProductsModule from './Icons/Modules/digital-products.svg';
import AdminCalendarModule from './Icons/Modules/calendar.svg';
import BillingModule from './Icons/Modules/billing.svg';
import ProductsModule from './Icons/Modules/products.svg';

import DashboardSettingsModule from './Icons/Modules/dashboardSettings.svg';
import SalesSettingsModule from './Icons/Modules/salesSettings.svg';
import LogisticSettingsModule from './Icons/Modules/logisticSettings.svg';
import PurchasesSettingsModule from './Icons/Modules/purchasesSettings.svg';
import MessagesSettingsModule from './Icons/Modules/mailSettings.svg';
import WarehouseSettingsModule from './Icons/Modules/warehouseSettings.svg';
import CustomersSettingsModule from './Icons/Modules/customersSettings.svg';
import SuppliersSettingsModule from './Icons/Modules/suppliersSettings.svg';
import DesignServiceSettingsModule from './Icons/Modules/designSettings.svg';
import OnlineArchiveSettingsModule from './Icons/Modules/archiveSettings.svg';
import ShopSettingsModule from './Icons/Modules/shopSettings.svg';
import AccountingSettingsModule from './Icons/Modules/accountingSettings.svg';
import ListingSettingsModule from './Icons/Modules/listingSettings.svg';
import ProductsSettingsModule from './Icons/Modules/productsSettings.svg';
import SettingsModule from './Icons/Modules/settingsModule.svg';

import Doc from './Icons/TopMenu/doc.svg';
import Euro from './Icons/TopMenu/euro.svg';
import Delivery from './Icons/TopMenu/delivery.svg';
import Printer from './Icons/TopMenu/print.svg';
import EmailAt from './Icons/TopMenu/email.svg';
import CreateFeedback from './Icons/TopMenu/create-feedback.svg';
import ShippingLabel from './Icons/TopMenu/shipping-label.svg';
import ChangeOtherStatuses from './Icons/TopMenu/other-statuses.png';

import User from './Icons/TopMenu/user.svg';
import SettingsIcon from './Icons/TopMenu/settings.svg';
import Question from './Icons/TopMenu/question.svg';
import Notification from './Icons/TopMenu/notification.svg';

import BlueDock from './Icons/blue-doc.svg';
import DarkDock from './Icons/dark-doc.svg';

import Lock from './Icons/lock.svg';
import Unlock from './Icons/unlock.png';

import ControlEdit from './Icons/Controls/edit.png';
import ControlSubmit from './Icons/Controls/submit.png';
import ControlClear from './Icons/Controls/clear.svg';

import RefDocs from './Icons/refDocs.svg';
import ModalClose from './Icons/modalClose.svg';

import Customer from './Icons/customer.png';
import CustomerDoc from './Icons/customerDoc.png';
import Incoming from './Icons/incoming.png';
import Outcoming from './Icons/outcoming.png';

import DropDownShown from './Icons/dropDownShown.svg';
import DropDownHidden from './Icons/dropDownHidden.svg';
import DropDownPlus from './Icons/dropDownPlus.svg';
import DropDownMinus from './Icons/dropDownMinus.svg';

import Folder from './Icons/folder.svg';

import Search from './Icons/search.svg';
import Cancel from './Icons/cancel.svg';
import Confirm from './Icons/confirm.svg';
import TableSearch from './Icons/Controls/tableSearch.svg';
import TableFilter from './Icons/Controls/tableFilter.svg';

import TemplateRestore from './Icons/template-restore.png';

import ScannerIcon from './Icons/scanner-icon.svg';

import Flag from './Icons/flag.png';
import FlagDanger from './Icons/flag-danger.png';

import MailOpened from './Icons/mail-opened.png';
import MailClosed from './Icons/mail-closed.png';

import Up from './Icons/Controls/up.svg';
import Down from './Icons/Controls/down.svg';
import Insert from './Icons/Controls/insert-value.svg';

import Show from './Icons/show.svg';
import Hide from './Icons/hide.svg';

import NotifyCircle from './Icons/notify-circle.svg';

import SkuNotFound from './Icons/sku-not-found.svg';
import NotEnoughProducts from './Icons/not-enough-products.svg';
import ProductRunningOut from './Icons/product-running-out.svg';
import BuyerNoteExist from './Icons/buyer-note-exist.svg';

import Amazon from './Marketplaces/amazon.svg';
import Ebay from './Marketplaces/ebay.svg';
import Kaufland from './Marketplaces/kaufland.svg';
import Ozon from './Marketplaces/ozon.svg';

import Pickup from './Icons/pickup.svg';
import Curier from './Icons/curier.svg';

import Sun from './Icons/sun.svg';
import Moon from './Icons/moon.svg';
import Learn from './Icons/learn.svg';

export const ControlIcons = {
  ControlEdit,
  ControlSubmit,
  ControlClear,
  Up,
  Down,
  TableSearch,
  TableFilter,
  Insert,
};

export const TopMenuIcons = {
  Doc,
  Euro,
  Delivery,
  Printer,
  EmailAt,
  CreateFeedback,
  User,
  SettingsIcon,
  Question,
  Notification,
  ShippingLabel,
  ChangeOtherStatuses,
  Sun,
  Moon,
  Learn,
};

export const ModuleIcons = {
  Synchronize,
  DashboardModule,
  LogisticModule,
  SalesModule,
  PurchasesModule,
  MessagesModule,
  DocumentModule,
  WarehouseModule,
  CustomersModule,
  SuppliersModule,
  DesignServiceModule,
  AdminModule,
  OnlineArchiveModule,
  ShopModule,
  LanguagesModule,
  AccountingModule,
  ListingModule,
  SubscriptionsModule,
  DigitalProductsModule,
  AdminCalendarModule,
  BillingModule,
  ProductsModule,

  DashboardSettingsModule,
  SalesSettingsModule,
  LogisticSettingsModule,
  PurchasesSettingsModule,
  MessagesSettingsModule,
  WarehouseSettingsModule,
  CustomersSettingsModule,
  SuppliersSettingsModule,
  DesignServiceSettingsModule,
  OnlineArchiveSettingsModule,
  ShopSettingsModule,
  AccountingSettingsModule,
  ListingSettingsModule,
  ProductsSettingsModule,
  SettingsModule,
};

export const Icons = {
  Logo,

  Doc,

  Synchronize,
  Purchases,
  EmailAt,

  Delete,

  Add,
  AddBlue,
  Save,
  Back,
  Copy,
  Edit,
  EditLight,
  Enter,
  ConnectionLost,

  Merge,
  Divide,

  Home,
  Customers,
  Cart,

  Check,
  Invalid,

  Printer,
  EmailBlue,

  Lock,
  Unlock,

  BlueDock,
  DarkDock,
  AddressWarning,
  RefDocs,
  ModalClose,

  Customer,
  CustomerDoc,
  Incoming,
  Outcoming,

  DropDownShown,
  DropDownHidden,
  DropDownPlus,
  DropDownMinus,

  Folder,

  Search,
  Cancel,
  Confirm,

  TemplateRestore,

  ScannerIcon,

  Flag,
  FlagDanger,

  MailOpened,
  MailClosed,

  Show,
  Hide,

  NotifyCircle,

  SkuNotFound,
  NotEnoughProducts,
  ProductRunningOut,
  BuyerNoteExist,

  Amazon,
  Ebay,
  Kaufland,
  Ozon,

  Curier,
  Pickup,
};

export type IconProps = {
  size?: 'small' | 'normal' | 'large';
  onClick?: (event?: any) => void;
  disabled?: boolean;
  dataAttributes?: DataProps;
  id?: string;
} & ClassNameProps;
