import React, { useEffect, useMemo, useState } from 'react';
import { ClassNameProps, DisabledProps, InputRefProps } from '@/Types';
import { useFormError, useFormSetValue } from '@/Hooks';
import jQuery from 'jquery';
import daterangepicker from 'daterangepicker';
import 'daterangepicker/daterangepicker.css';
import cn from 'classnames';
import { ErrorTooltip, errorTooltipClass } from '@/Components/Controls/ErrorTooltip/ErrorTooltip';
import { useDaterangeDefaultOptions } from './hooks/useDaterangeDefaultOptions';
import { useDaterangePickerLocale } from './hooks/useDaterangePickerLocale';
import { useDaterangePickerRanges } from './hooks/useDaterangePickerRanges';
import { useDateRangeEventListeners } from './hooks/useDaterangeEventListeners';
import { v4 as uuidv4 } from 'uuid';
import { EMPTY_ACTION } from '@/Constants/SharedConstants';
import { formDateInputUtils } from '@/Components/Controls/DateInput/utils';
import styles from './FormDateInput.scss';
import { DateFormatType } from '@/Enums';

type Props<TFormData extends object> = {
  showCalendar?: boolean;
  showCustomRangeLabel?: boolean;
  singleDatePicker?: boolean;
  valueForSet?: [string, string] | string;
  pickerSetValue: (name: any, value: any, config?: Partial<{ shouldValidate: boolean; shouldDirty: boolean }>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (val: string) => void;
  minDate?: string | Date;
  maxDate?: string | Date;
  rangesType?: 'orderDateRanges' | 'defaultDateRanges';
  /**
   * Use this property if you need dates with time.
   * Also custom date ranges with special time would not work if this property false.
   */
  needTimePicker?: boolean;
} & ClassNameProps &
  DisabledProps &
  Omit<InputRefProps<HTMLInputElement, TFormData, string | number>, 'valueForSet'>;

export function FormDateInput<TFormData extends object>({
  name,
  setValue = () => null,
  onFocus,
  onBlur,
  onChange,
  error,
  className,
  showCalendar = true,
  showCustomRangeLabel = false,
  singleDatePicker = true,
  disabled = false,
  register,
  rules,
  valueForSet,
  pickerSetValue,
  minDate,
  maxDate,
  needTimePicker = false,
  rangesType,
}: Props<TFormData>) {
  useFormSetValue(name, valueForSet, setValue);

  const errorMessage = useFormError(name, error);
  const [displayValue, setDisplayValue] = useState('');

  const inputId = useMemo(() => uuidv4(), []);
  const inputRef = register?.(name as any, rules);

  // Options
  const ranges = useDaterangePickerRanges(rangesType);
  const locale = useDaterangePickerLocale({ needTimePicker });
  const defaultOptions = useDaterangeDefaultOptions({
    singleDatePicker,
    needTimePicker,
    showCustomRangeLabel,
    showCalendar,
    valueForSet,
    minDate,
    maxDate,
    format: locale.format,
  });

  useEffect(() => {
    setDisplayValue(
      formDateInputUtils.convertDateOrRangeToString(valueForSet, ranges, locale.format as DateFormatType),
    );
  }, [valueForSet]);

  // Event listeners
  const { onApply, onCancel } = useDateRangeEventListeners({
    format: locale.format,
    isRange: !singleDatePicker,
    onChange,
  });

  // Daterange options
  const options: daterangepicker.Options = {
    ...defaultOptions,
    locale: locale,
    ranges,
    minYear: 1900,
    maxYear: 2200,
    showDropdowns: true,
    timePickerSeconds: true,
  };

  useEffect(() => {
    // Daterange initializing
    const input = jQuery(`#${inputId}`).get(0);

    if (input) {
      const daterange = new daterangepicker(input, options);

      // Daterange event handlers
      jQuery(`#${inputId}`).on('apply.daterangepicker', (event, picker) => {
        const chosenLabel = picker?.chosenLabel;
        if (chosenLabel) {
          setDisplayValue(chosenLabel);
        } else {
          setDisplayValue(formDateInputUtils.formatPicker(picker, locale.format, !singleDatePicker));
        } // if

        onApply(name, picker, pickerSetValue);
      });

      jQuery(`#${inputId}`).on('cancel.daterangepicker', () => {
        onCancel(name, pickerSetValue);
        setDisplayValue('');
      });

      jQuery(`#${inputId}`).on('hide.daterangepicker', () => {
        onBlur?.();
      });

      return () => daterange.remove();
    } // if
  }, [options, pickerSetValue, inputId, locale, singleDatePicker, singleDatePicker]);

  useEffect(() => {
    setDisplayValue(
      formDateInputUtils.convertDateOrRangeToString(
        String(jQuery(`#${inputId}`).val()),
        ranges,
        locale.format as DateFormatType,
      ),
    );
  }, [inputRef]);

  return (
    <ErrorTooltip error={errorMessage} className={className}>
      <input
        type="text"
        id={String(inputId)}
        {...inputRef}
        name={(name as unknown) as string}
        disabled={disabled}
        className={cn(styles.daterange__hidden)}
        autoComplete={'off'}
        readOnly={true}
      />
      <input
        type="text"
        title={displayValue}
        id={`${inputId}_visible`}
        disabled={disabled}
        className={cn(styles.daterange, { [errorTooltipClass]: errorMessage })}
        autoComplete={'off'}
        readOnly={false}
        onClick={() => {
          jQuery(`#${inputId}`).trigger('click');
        }}
        onFocus={() => onFocus?.()}
        onChange={EMPTY_ACTION}
        value={displayValue}
      />
    </ErrorTooltip>
  );
}
