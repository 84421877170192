import React from 'react';
import styles from './HelpSidebar.scss';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { tourSelectors } from '@/Redux/Tours/selectors';
import { tourActions } from '@/Redux/Tours/reducer';
import { useRouteItems } from './hooks/useRouteItems';
import { TourList } from './modules/TourList/TourList';
import { RouteItem } from './modules/RouteItem/RouteItem';

export const HelpSidebar = () => {
  const dispatch = useDispatch();

  const openSidebar = useSelector(tourSelectors.openSidebar);
  const liftSidebar = useSelector(tourSelectors.liftSidebar);
  const selectedRoute = useSelector(tourSelectors.selectedRoute);
  const { routeItems } = useRouteItems();

  const closeSidebar = () => {
    dispatch(tourActions.setOpenSidebar(false));
  };

  return (
    <div className={cn(styles.sidebar, { [styles.open]: openSidebar, [styles.liftSidebar]: liftSidebar })}>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarHeader}>
          <h3>Help & Support</h3>
          <button className={styles.closeBtn} onClick={closeSidebar}>
            &times;
          </button>
        </div>

        <div className={styles.sidebarContent}>
          {!selectedRoute ? (
            <>
              {routeItems.map((item, idx) => (
                <RouteItem key={item.title} idx={idx + 1} node={item} />
              ))}
            </>
          ) : (
            <TourList />
          )}
        </div>
      </div>
    </div>
  );
};
